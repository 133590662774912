import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';

@Injectable({ providedIn: 'root' })
export class HomeService {

  pathProduct: string = 'admin/public/product?populate=true&populate_items=config.origins:name,_id';
  pathComents: string = 'admin/public/comment';
  seasonActive: boolean = true;
  seasonMessage: any = {
    es: '',
    en: '',
    eu: ''
  };
  initializated: boolean = false;

  constructor(private apiService: ApiService) { }

  setSeasonActive(active: boolean): void{
    this.seasonActive = active;
  }

  getSeasonActive(): boolean {
    return this.seasonActive;
  }

  setSeasonMessage(seasonMessage: any): void{
    this.seasonMessage = seasonMessage;
  }

  getSeasonMessage(): any {
    return this.seasonMessage;
  }

  getProducts() {
    return this.apiService.get(this.pathProduct);
  }

  getProduct(id: number) {
    return this.apiService.get(`${this.pathProduct}/${id}`);
  }

  getComments() {
    return this.apiService.get(this.pathComents);
  }

  setInitializated(initializated: boolean): void{
    this.initializated = initializated;
  }

  getInitializated(): boolean {
    return this.initializated;
  }


}
